import React from "react";

// Layouts
import UserLayout from "src/layouts/UserLayout";
import PublicLayout from "src/layouts/PublicLayout";
import HomeLayout from "src/layouts/HomeLayout";
import CenteredLayout from "src/layouts/CenteredLayout";
import DevLayout from "src/layouts/DevLayout";

import Roles from "./Roles";
import { KeyMapper, Omit } from "./CoreTypes";

export const AllLayoutMapper = {
  userLayout: UserLayout,
  publicLayout: PublicLayout,
  homeLayout: HomeLayout,
  centeredLayout: CenteredLayout,
  devLayout: DevLayout,
};

// export interface AllLayoutMapper {
//   userLayout: typeof UserLayout;
//   publicLayout: typeof PublicLayout;
//   homeLayout: typeof HomeLayout;
//   centeredLayout: typeof CenteredLayout;
//   devLayout: typeof DevLayout;
// }

export type AllLayoutKeys = keyof typeof AllLayoutMapper;

export const DefaultLayout = PublicLayout;
export const DefaultUserLayout = UserLayout;

export type CommonProps = {
  /**
   * Unique. Name of the page. Really used for developer use when reviewing. Also used when generating pages
   */
  key: string;

  /**
   * Unique. Route to the page
   */
  route: string;

  /**
   * The default layout to render
   */
  layout: AllLayoutKeys;
};

export type ComponentProps = {
  type: "basic";
  /**
   * Component to render
   */
  component: React.ElementType;
};

export type RoleProps = {
  type: "role";

  /**
   * Role descriptor for the given page. Each role must have an associated component to render on that page. Any missing roles will fallback to the `component` prop then to the "not found" component
   */
  roles: Partial<KeyMapper<Omit<typeof Roles, "none" | "all">, React.ElementType>> & { [key: string]: React.ElementType }; // TODO: Make the index thingy a core type

  /**
   * The layout to render when the user is not in the `roles` property. Will fallback to the default layout
   */
  publicLayout?: AllLayoutKeys;

  /**
   * The default component if the user is not found in the `roles` property. Will fallback to the default 404 page
   */
  component?: React.ElementType;
};

/** The new and improved interface, complete with role support and proper typescript restrictions */
export type PageInterface = CommonProps & (ComponentProps | RoleProps);
