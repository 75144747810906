import React, { createContext, useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { useLocation, useSearchParams } from "react-router-dom";
import { useStateAsync } from "src/hooks/UseStateAsync";
import AuthorizationController from "src/api/AuthorizationController";
import LoginResultDTO from "src/models/generated/LoginResultDTO";
import AccountController from "src/api/AccountController";
import UserProfileDTO from "src/models/generated/UserProfileDTO";
import Roles, { Role } from "src/core/Roles";
import RegisterDTO from "src/models/generated/RegisterDTO";

export interface AuthenticationContextType {
  /** The user object. Available when isLoggedIn is true */
  profile: UserProfileDTO | null; // Trying out calling it profile instead of user

  /** The auth object used with the login process */
  auth: LoginResultDTO | null;

  /** Used for reporting and the debug overlay */
  loginTimeStamp: moment.Moment | null;

  /** Easy access to the user role */
  userRole: Role | undefined;

  /** Just for you, so you can check if the user is logged in easily */
  isLoggedIn: boolean;

  /** Indicates that the authContext has finished determining the current state and is ready to use */
  isInitialized: boolean;

  login_email_pass: (email: string, password: string) => Promise<LoginResultDTO>;
  login_two_factor: (twoFactor: string) => Promise<AuthResponse>;
  login_activate: (confirmationCode: string) => Promise<AuthResponse>;
  register: (values: RegisterDTO) => Promise<LoginResultDTO>;
  logout: () => Promise<boolean>;

  // I think I will use this when the time runs out for a user
  refresh: () => Promise<boolean>;

  // Needed when the user updates their profile info
  updateProfile: (profile: UserProfileDTO | null) => Promise<void>;

  // Async retrieval for the profile and auth objects
  getProfile: () => Promise<UserProfileDTO | null>;
  getAuth: () => Promise<LoginResultDTO | null>;
}

export interface AuthResponse {
  requiresTwoFactorLogin: boolean;
  isLoggedIn: boolean;
  /** Indicates that the request itself was a success. Will be false if stuff like the password or two factor code is invalid */
  isSuccess: boolean;
  message: string;
}
const AuthResponse = {
  create: (initValues?: Partial<AuthResponse>): AuthResponse => {
    const defaults: AuthResponse = {
      requiresTwoFactorLogin: false,
      isLoggedIn: false,
      isSuccess: false,
      message: ""
    };
    return { ...defaults, ...initValues };
  },
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthenticationContext = createContext<AuthenticationContextType>(undefined!);

export const AuthenticationProvider = (props: React.PropsWithChildren<any>) => {
  const routerLocation = useLocation();
  const [profile, setProfile, getProfileAsync] = useStateAsync<UserProfileDTO | null>(null);
  const [auth, setAuth, getAuthAsync] = useStateAsync<LoginResultDTO | null>(null);
  const [loginTimeStamp, setLoginTimeStamp] = useState<moment.Moment | null>(null);
  const [userRole, setUserRole] = useState<Role | undefined>();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  // OnLoad
  useEffect(() => {
    ensureUser();
  }, []);

  const ensureUser = async () => {
    try {
      // This project should be significantly easier since I control everything

      // Get current auth state, which will either be completely null or be in some state we can work with. The API will be responsible for figuring that out, RIP future me
      // The isAuthenticated flag gets set when we are good to go after password, tfa and probably email confirmation (need to verify that last one)
      const results = await AuthorizationController.get();
      setAuth(results.data);
      setUserRole(Roles.all.find(x => x.key === results.data.roleId));
      if (results.data.isAuthenticated) {
        // We can fetch the profile if we are fully authed
        const profileResults = await AccountController.getCurrentUserProfile();
        setProfile(profileResults.data);
        setIsLoggedIn(true);
        setLoginTimeStamp(moment());
        setIsInitialized(true);
        return;
      }
    } catch (error) {
      // Something done borked, only sensible thing at this point is to run the logout process
      return unloadComponent(); // This doesn't actually return anything, it's just 1 less line
    }

    // Welp, we are somewhere in the login process
    setIsLoggedIn(false);
    setLoginTimeStamp(null);
    setIsInitialized(true);
  };

  const login_email_pass = async (emailAddress: string, password: string): Promise<LoginResultDTO> => {
    if (!isInitialized) {
      return LoginResultDTO.create({ hasError: true, message: "Provider not initialized yet!" });
    }

    try {
      const emailLoginResult = await AuthorizationController.login({ emailAddress, password });
      const authProfile = emailLoginResult.data;

      if (authProfile.isAuthenticated) {
        setIsLoggedIn(true);
      }

      // Well that was easy
      setAuth(authProfile);
      setUserRole(Roles.all.find(x => x.key === authProfile.roleId));

      // Will need some updates to this guy
      return authProfile;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Bad requests come in as an axios error, so we can still grab the data and do something with it
        const errorResult = error.response!.data! as LoginResultDTO;

        setAuth(errorResult);
        setUserRole(undefined);

        return errorResult;
      } else {
        // Bad errors end up here
        return LoginResultDTO.create({ hasError: true, message: `An unknown error has occurred: ${error}` });
      }
    }
  };

  const login_two_factor = async (): Promise<AuthResponse> => {
    return AuthResponse.create();
  };

  const login_activate = async (): Promise<AuthResponse> => {
    return AuthResponse.create();
  };

  const register = async (values: RegisterDTO): Promise<LoginResultDTO> => {
    // Okay! We get to register a new user, wooo!
    // Easy enough, just send the values to the api and setup the context with the results
    // Unless there is an error, which we will toss back to the caller (probably the form)
    if (!isInitialized) {
      return LoginResultDTO.create({ hasError: true, message: "Provider not initialized yet!" });
    }

    try {
      const results = await AuthorizationController.registerUser(values);
      const authProfile = results.data;

      // If the registration was successful, the user is already logged in. Might be a good idea, might not, that is for future me to find out
      if (authProfile.isAuthenticated) {
        setIsLoggedIn(true);
      }

      setAuth(authProfile);
      setUserRole(Roles.all.find(x => x.key === authProfile.roleId));

      return authProfile;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        // Error now becomes an axios error through typescript magic
        return LoginResultDTO.create(error.response?.data as LoginResultDTO);
      } else {
        // Could have just been a normal error, rethrow for now and let the console deal with it
        throw error;
      }
    }
  };

  const logout = async (): Promise<boolean> => {
    unloadComponent();
    // Should hit the logout url as well
    return true;
  };

  const refresh = async (): Promise<boolean> => {
    return true;
  };

  const unloadComponent = () => {
    setIsLoggedIn(false);
    setIsInitialized(true); // Kind of strange but since this is normally called on logout, it makes sense. We are ready, there is no user!
    setLoginTimeStamp(null);
    setProfile(null);
    setAuth(null);
  };

  const updateProfile = (newProfile: UserProfileDTO | null) => {
    return new Promise<void>(resolve => {
      setProfile(newProfile);
      resolve();
    });
  };

  return (
    <AuthenticationContext.Provider value={{
      profile,
      auth,
      loginTimeStamp,
      userRole,
      isLoggedIn,
      isInitialized,
      login_email_pass,
      login_two_factor,
      login_activate,
      register,
      logout,
      refresh,
      getProfile: getProfileAsync,
      getAuth: getAuthAsync,
      updateProfile
    }}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};
