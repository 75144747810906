import moment from "moment";

class ObjectUtil {
  /***
   * General note about 'object'. Typescript has decided that you should avoid it, so Record<string, unknown> is used
   * From MS: "Don't use `object` as a type. The `object` type is currently hard to use"
   ***/

  public ToKeyValuePairs(theObject: Record<string, unknown>): { key: string; value: unknown }[] {
    return Object.entries(theObject).map(([key, value]) => ({ key, value }));
  }

  /**
   * Trims the string properties on an object, returning a shallow cloned object. Properties are shallowly trimmed
   * @param obj The Object to have it's properties trimmed
   * @param keys Optional: If provided, it will only trim these keys. It will skip those that are not `typeof 'string'`
   */
  public TrimValues<T extends Record<string, any>>(obj: T, ...keys: (keyof T)[]): T {
    if (keys.length > 0) {
      let newObj = { ...obj };
      keys.forEach(key => {
        const property = newObj[key];
        if (typeof property === "string") {
          newObj[key] = property.trim() as any;
        }
      });
      return newObj;
    }
    return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
      if (typeof value === "string") {
        value = value.trim();
      }
      return [key, value];
    })) as any;
  }

  /**
   * Converts a given value to boolean.
   */
  public ToBoolean(input?: unknown | null): boolean {
    // Dev Note: It all really depends on what you want to guard for/against. I decided to make 'false' the default, so weird values get defaulted to false. The feels like the safer approach
    if (input == null) {
      return false;
    }

    switch (typeof input) {
      case "boolean":
        return input;
      case "bigint":
      case "number":
        // This one is a bit harder. Does a 2 count as true? What about negatives? Hmmm
        return input > 0;
      case "string":
        // String has some weirdness. Json values coming in can have any number of weirdness
        switch (String(input).toLowerCase().trim()) {
          case "true":
          case "yes":
          case "1":
            return true;
          default:
            return false;
        }
      // Can't do much with the rest so just fail to false
      default:
        return false;
    }
  }

  /**
   * Helper function to convert a value to moment. Mainly used for parsing data from an API request.
   *
   * Invalid input will return null, including null, instead of an invalid moment object
   */
  public ToMoment(input?: any | null): moment.Moment | null {
    // Dev Note: can't use unknown as the moment constructor get's angry
    if (input == null) {
      return null;
    }

    const output = moment(input);
    return output.isValid() ? output : null;
  }

  public CamelCaseKeys(obj: any, deep: boolean = false, ...manualReplacements: [string, string][]): any {
    // Type guard
    if (obj == null || typeof obj !== "object") {
      // if (process.env.NODE_ENV === 'development') {
      //   console.error('[CamelCaseKeys] Cannot handle values other than object', { value: obj, type: typeof obj });
      // }
      return obj;
    }

    // We can handle arrays
    if (Array.isArray(obj)) {
      return Array.from(obj.map(x => this.CamelCaseKeys(x, deep, ...manualReplacements)));
    }

    const entries = Object.entries(obj).map(([key, value]) => {
      // Check manual replacements against the key
      const foundReplacement = manualReplacements.find(([finder, replacer]) => key.startsWith(finder));

      // Replace text if found, generate the key if not
      const newKey = foundReplacement == null
        ? key.charAt(0).toLowerCase() + key.slice(1)
        : foundReplacement[1] + key.slice(foundReplacement[0].length);

      // Optionally iterate the sub objects
      if (deep && value != null && typeof value === "object") {
        return [newKey, this.CamelCaseKeys(value, deep, ...manualReplacements)];
      }
      return [newKey, value];
    });

    // Reconstruct the object from the entries
    return Object.fromEntries(entries);
  }

  public UnCamelCaseKeys(obj: any, deep: boolean = false, ...manualReplacements: [string, string][]): any {
    // Type guard
    if (obj == null || typeof obj !== "object") {
      console.error("[UnCamelCaseKeys] Cannot handle values other than object", { value: obj, type: typeof obj });
      return obj;
    }

    // We can handle arrays
    if (Array.isArray(obj)) {
      return Array.from(obj.map(x => this.UnCamelCaseKeys(x, deep, ...manualReplacements)));
    }

    let entries = Object.entries(obj).map(([key, value]) => {
      // Check manual replacements against the key
      const foundReplacement = manualReplacements.find(([finder, replacer]) => key.startsWith(finder));

      // Replace text if found, generate the key if not
      const newKey = foundReplacement == null
        ? key.charAt(0).toUpperCase() + key.slice(1)
        : foundReplacement[1] + key.slice(foundReplacement[0].length);

      // Optionally iterate the sub objects
      if (deep && value != null && typeof value === "object") {
        return [newKey, this.UnCamelCaseKeys(value, true, ...manualReplacements)];
      }
      return [newKey, value];
    });

    // Reconstruct the object from the entries
    return Object.fromEntries(entries);
  }
}

export default new ObjectUtil();
