import axios from "axios";
import moment from "moment";

const InitializeAxios = (): void => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

  // We accept and use only json
  axios.defaults.headers.common["Accept"] = "application/json";
  axios.defaults.headers.common["Content-Type"] = "application/json";
  axios.defaults.withCredentials = true;

  // return;

  /** Interceptors **/
  /**** ORDER MATTERS! REQUEST INTERCEPTORS ARE RUN IN REVERSE ORDER FROM WHEN THEY ARE ADDED ****/

  /**
   * Delay
   *  Add an artificial delay so we can test the loading logic
   */
  // axios.interceptors.response.use(async (response) => {
  //   await sleep(300);

  //   return response;
  // });

  /**
   * Proxy support
   *  Proxies the request through the Azure Function. This must be the first request interceptor or you will have a very bad time
   */
  // if (process.env.REACT_APP_USE_PROXY != null && process.env.REACT_APP_USE_PROXY.trim().localeCompare('true') === 0) {
  //   axios.interceptors.request.use(
  //     request => {
  //       request.data = {
  //         url: request.url,
  //         params: request.params,
  //         data: request.data != null ? request.data : undefined,
  //         method: request.method,
  //         auth: axios.defaults.headers.common['Authorization']
  //       };
  //       request.method = 'post';
  //       request.baseURL = process.env.REACT_APP_BEARER_URL;
  //       request.url = '/api/apiProxy';
  //       request.params = {};

  //       return request;
  //     },
  //     error => {
  //       return Promise.reject(error);
  //     }
  //   );
  // }

  /**
   * Dates Converter
   *  Updates the request's dates to be in the proper format as we cannot just return a Moment to the API. It will also need to be in UTC format
   */
   axios.interceptors.request.use(
    request => {
      const original = request.data;
      if (original == null) {
        return request;
      }

      // Dev Note: Types are ALL weird because of the array type guard
      let convertMomentsFunc = (obj: any | any[]): any | any[] => {
        // Arrays are fun and break things if not accounted for
        if (Array.isArray(obj)) {
          return obj.map(convertMomentsFunc);
        }

        return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
          // Checks for intrinsic types (string/number/etc.), null and undefined
          if (value == null || typeof value !== "object") {
            return [key, value];
          }

          // Check for moment. If not, then it's potentially a deeply nested object, so we should dig into that
          if (moment.isMoment(value)) {
            value = value.toISOString(true);
          } else {
            value = convertMomentsFunc(value);
          }
          return [key, value];
        }));
      };

      // We need to unCamelCase the request, which handles the bulk of the values (Updated 5/8/22 to handle everything in the util class!)
      let converted = convertMomentsFunc(original);

      request.data = converted;

      // if (process.env.NODE_ENV === 'development') {
      //   console.log('API: Changed request data via Dates Converted', { original, converted }); // JB - Remove this console.warn
      // }

      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

export { InitializeAxios };
