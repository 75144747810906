import * as React from "react";
import moment from "moment";

/**
 * This requires `moment` and the `REACT_APP_BUILD_DATE` and `NODE_ENV` parameters to be set by node
 * @param outputFunc Optional. Redirects the output somewhere else. Defaults to `console.warn`
 */
export default function RenderBuildInfo(outputFunc: (message?: string) => void = console.warn): void {
    if (process.env.REACT_APP_BUILD_DATE == null) {
        console.error(
            "Warning: REACT_APP_BUILD_DATE has not been properly set and a build date cannot be determined. Please set the value with: process.env.REACT_APP_BUILD_DATE = (moment()).toISOString();"
        );
        return;
    }

    const buildDate = moment(process.env.REACT_APP_BUILD_DATE);
    const buildString = buildDate.isSameOrAfter(moment(), "day")
        ? // Build was made today
          `Build created Today at ${buildDate.format("LT")}`
        : buildDate.isSameOrAfter(moment().add(-1, "days"), "day")
        ? // Build was made yesterday
          `Build created Yesterday at ${buildDate.format("LT")}`
        : // Build was much longer ago
          `Build created on (${buildDate.format("LL")}) at ${buildDate.format("LT")}`;

    outputFunc(`Environment: ${process.env.NODE_ENV}`);
    outputFunc(buildString);
}
