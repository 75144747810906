import React, { useContext, useEffect } from "react";

const LoginForgotPasswordConfirmPage: React.FC = () => {
  return (
    <div className='login-forgot-password-confirm'>
      <h1>Password Reset</h1>
      <p>A link has been sent to your email if an account exists. Please check your spam if you do not receive an email in the next few minutes</p>
    </div>
  );
};

export default LoginForgotPasswordConfirmPage;
