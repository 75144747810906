import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Space, Button, Form, Input } from "antd";
import { NameOf, NotificationUtil, ObjectUtil } from "src/utils";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RouteConfig from "src/config/RouteConfig";

interface LoginPasswordFormData {
  email: string;
  password: string;
}

const LoginPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const location = useLocation(); // Current Location
  const navigate = useNavigate(); // Ability to navigate
  const [form] = Form.useForm<LoginPasswordFormData>();
  const [loading, setLoading] = useState(false);

  const redirectUser = async () => {
    const auth = await authContext.getAuth();
    if (auth == null) {
      return;
    }

    // TODO: Really need a helper method or a check against constants
    if (auth.isAuthenticated) {
      // TODO: Really need a helper method or a check against constants
      const navLocation = auth.roleId === 1 // Admin
        ? RouteConfig.INTERPRETER_HOME
        : auth.roleId === 2 // Moderator (still not sure what I am thinking with this one. Probably should remove it for now or rename it support agent)
          ? RouteConfig.INTERPRETER_HOME
          : auth.roleId === 3 // Interpreter
            ? RouteConfig.INTERPRETER_HOME
            : auth.roleId === 4 // User
              ? RouteConfig.HOME
              : RouteConfig.HOME;

      navigate(navLocation()); // Probably should move the function call too, what are you thinking tonight??
    } else if (!auth.isActivated) {
      navigate(RouteConfig.LOGIN_ACTIVATE());
    } else if (auth.isTFAEnabled && !auth.isTFAVerified) {
      navigate(RouteConfig.LOGIN_TWO_FACTOR());
    }
  };

  const handleOnFinish = async (values: LoginPasswordFormData) => {
    // Trim values. We are including password in this because you should not have a space in your password. That is just bad
    values = ObjectUtil.TrimValues(values);

    // Okay, we get to submit to the API now!
    setLoading(true);
    const results = await authContext.login_email_pass(values.email, values.password);
    setLoading(false);

    if (results.hasError) {
      NotificationUtil.error({
        key: "LoginPage",
        message: "Login Error",
        description: `Error while logging in: ${results.message}`
      });
      return;
    }

    if (results.isAuthenticated) {
      NotificationUtil.success({
        key: "LoginPage",
        message: "Login Success",
        description: results.message
      });
    } else if (!results.isActivated) {
      // Dev Note: Might remove these in future
      NotificationUtil.info({
        key: "LoginPage",
        message: "Activation Required",
        description: results.message
      });
    } else if (results.isTFAEnabled) {
      // Dev Note: Might remove these in future
      NotificationUtil.info({
        key: "LoginPage",
        message: "TFA Required",
        description: results.message
      });
    }

    await redirectUser();
  };

  useEffect(() => {
    // Check if user is already logged in
    if (authContext.isLoggedIn) {
      console.log("[LoginPage] User is logged in, redirecting");
      redirectUser();
    }
  }, []);

  return (
    <div className='home-page'>
      <h1>Login</h1>

      <div className='login-form'>
        <Form
          size='large'
          layout='vertical'
          requiredMark={false}
          form={form}
          onFinish={handleOnFinish}
        >
          <Form.Item
            name={NameOf<LoginPasswordFormData>("email")}
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Email is not valid" }
            ]}>
            <Input allowClear disabled={loading} placeholder='Email address' />
          </Form.Item>
          <Form.Item
            name={NameOf<LoginPasswordFormData>("password")}
            rules={[
              { required: true, message: "Password is required" }
            ]}>
            <Input.Password placeholder='Password' allowClear disabled={loading} />
          </Form.Item>

          <Form.Item style={{ marginTop: 8 }}>
            <Button type='primary' htmlType='submit' block>Login</Button>
          </Form.Item>
        </Form>

        <Link to={RouteConfig.REGISTER()}>
          <Button type='link' size='large' block>Need to Register?</Button>
        </Link>
        <Link to={RouteConfig.LOGIN_FORGOT_PASSWORD()}>
          <Button type='link' size='large' block>Forgot Password</Button>
        </Link>
      </div>

    </div>
  );
};

export default LoginPage;
