// This file is to house the various 'expressions' that are used in the app, such as dropdown props or autocomplete fixes
import { DropDownProps } from "antd/lib/dropdown";
import { SelectProps, SelectValue } from "antd/lib/select";

export type CompareType = "string" | "number" | "date" | "unknown";

class StandardExpressions {
  /**
   * A fix for the <Select> element that adds support to the `getPopupContainer` prop
   *
   * @example
   * ```tsx
   * <Select 'you props here' {...standardSelectProps}>
   * ```
   */
  public StandardSelectProps: Partial<SelectProps<SelectValue>> = {
    getPopupContainer: (trigger) => trigger.parentElement as HTMLElement
  };

  /**
   * A fix for the <Dropdown> element that adds support to the `getPopupContainer` prop
   *
   * @example
   * ```tsx
   * <Select 'you props here' {...standardDropdownProps} 'maybe more props here'>
   * ```
   */
  public StandardDropdownProps: Partial<DropDownProps> = {
    getPopupContainer: (trigger) => trigger.parentElement as HTMLElement
  };
}

export default new StandardExpressions();
