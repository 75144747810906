import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import RouteConfig from "src/config/RouteConfig";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import { NameOf, NotificationUtil, ObjectUtil } from "src/utils";
import AuthorizationController from "src/api/AuthorizationController";
import PasswordResetRequestDTO from "src/models/generated/PasswordResetRequestDTO";
import axios from "axios";

const LoginForgotPasswordPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm<PasswordResetRequestDTO>();

  const handleOnFinish = async (values: PasswordResetRequestDTO) => {
    // Trim values. We are including password in this because you should not have a space in your password. That is just bad
    values = ObjectUtil.TrimValues(values);

    // Okay, we get to submit to the API now!
    try {
      setLoading(true);
      const results = await AuthorizationController.passwordResetRequest(values);
      setLoading(false);

      // Show a notification that an email is on the way
      NotificationUtil.success({
        key: "password-reset-request-success",
        message: "Request Sent",
        description: "An email has been sent to your email address. Please follow the instructions in the email to reset your password.",
        duration: 0
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        NotificationUtil.error({
          key: "password-reset-request-error",
          message: "Error",
          description: (error.response?.data as any)?.message ?? error.message
        });
      }
    }

    // Send the user to the next few pages
    navigate(RouteConfig.LOGIN_FORGOT_PASSWORD_RESULT());
  };

  return (
    <div className='login-forgot-password' style={{ width: "min(350px, calc(100vw - 200px))" }}>
      <h1>Password Reset</h1>

      <div className='login-form'>
        <Form
          size='large'
          layout='vertical'
          requiredMark={false}
          form={form}
          onFinish={handleOnFinish}
        >
          <Form.Item
            name={NameOf<PasswordResetRequestDTO>("email")}
            rules={[
              { required: true, message: "Email is required" },
              { type: "email", message: "Email is not valid" }
            ]}>
            <Input allowClear disabled={loading} placeholder='Email address' />
          </Form.Item>

          <Form.Item style={{ marginTop: 8 }}>
            <Button type='primary' htmlType='submit' block loading={loading}>Submit</Button>
          </Form.Item>
        </Form>
      </div>

    </div>
  );
};

export default LoginForgotPasswordPage;
