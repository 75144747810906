/* eslint-disable prefer-rest-params */
import React from "react";
import { Button, notification } from "antd";

/**
 * Dev Note: Please rename this
 * Shows an error message when the website has disconnected from webpack.
 * Modifies: console.info - though it just captures the output to check for the dev server disconnection
 *   However, console.info is much less common that any of the other console functions, so this is fine
 */
export default function VerifyDevSeverConnection(): void {
  // Dev Note: No touchy! This overrides the console.info so that we can check up on the development server, showing a message if it gets disconnected
  const exInfo = console.info;
  console.info = function (msg: any) {
    const myFun = (Array.isArray(msg) ? msg[0] : msg) as string;

    // Check for messages output by webpack-dev-server. Prior to react-scripts@5, it was the first. After v5, we must look for the second
    if (myFun.indexOf("The development server has disconnected") === 0 || myFun.indexOf("Trying to reconnect...") > 0) {
      notification.error({
        key: "dev-server-disconnected",
        message: "Development Server Disconnected",
        description: "The development server has been disconnected. It is recommended that you refresh your browser",
        btn: (
          <Button type='primary' size='large' onClick={() => window.location.reload()}>
            Refresh Window
          </Button>
        ),
        duration: 0, // Show forever
        style: { width: 452 } // This is a good round number, ha
      });
    }

    // With changes in react-scripts@5 did and the config changes we had to add, the logs are much more annoying. This should fix it
    if (myFun.indexOf("[HMR]") === 0 || myFun.indexOf("[webpack-dev-server]") === 0) {
      return;
    }

    // eslint-disable-next-line prefer-rest-params
    exInfo.apply(console, arguments as any);
  };
}
