import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Input, Row, Skeleton, Space, Table, Upload, UploadFile, UploadProps, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import { Link } from "react-router-dom";
import { NameOf, StringUtil, TableColumnBuilder } from "src/utils";
import { AuthenticationContext } from "src/providers/AuthenticationContext";
import AdminUserController from "src/api/AdminUserController";
import AdminSimpleUserResponse from "src/models/generated/AdminSimpleUserResponse";
import RouteConfig from "src/config/RouteConfig";
import UserMediaDTO from "src/models/generated/UserMediaDTO";
import UploadMediaDTO from "src/models/generated/UploadMediaDTO";
import { UploadOutlined } from "@ant-design/icons";

const AdminUsersListPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<AdminSimpleUserResponse[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // File Uploader
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [fileUrl, setFileUrl] = useState("");
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    loadTable();
  }, []);

  const loadTable = async () => {
    setLoading(true);
    const profileResult = await AdminUserController.listUsers();

    setTableData(profileResult.data);
    setLoading(false);
  };

  const renderUploadFile = () => {
    const handleUpload = async () => {
      // const formData = new FormData();
      // fileList.forEach((file) => {
      //   // formData.append('files[]', file as RcFile);
      //   formData.append('files[]', file as any);
      // });
      setUploading(true);

      try {
        const results = await AdminUserController.createUserMassUpload(UploadMediaDTO.create({
          file: fileList[0],
          fileUrl: fileUrl
        }));
        message.success("upload successfully. Please refresh page to see changes");
      } catch (error) {
        message.error("upload failed." + error);
      }
      setUploading(false);
    };

    const props: UploadProps = {
      disabled: fileUrl.length > 0,
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
      },
      beforeUpload: (file) => {
        setFileList([...fileList, file]);

        return false;
      },
      fileList,
    };

    return (
      <>
      <Space>
        <Upload {...props}>
          <Button icon={<UploadOutlined />} disabled={fileUrl.length > 0}>Select File</Button>
        </Upload>
        <Input placeholder='Url' disabled={fileList.length > 0} onChange={e => setFileUrl(e.target.value)} />
        </Space>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length < 1 && fileUrl.length < 1}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? "Uploading" : "Upload CSV File"}
        </Button>
      </>
    );
  };

  const tableColumns: ColumnProps<AdminSimpleUserResponse>[] = [
    TableColumnBuilder.Create<AdminSimpleUserResponse>("id", "Id")
      .Width(70)
      .AddSorter("Number")
      .AddRenderer("Text")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>("firstName", "First Name")
      .Width(120)
      .AddSorter("Text")
      .AddRenderer("Ellipses")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>("lastName", "Last Name")
      .Width(120)
      .AddSorter("Text")
      .AddRenderer("Ellipses")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>("emailAddress", "Email")
      // .Width(200)
      .AddSorter("Text")
      .AddRenderer("Ellipses")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>("role", "Role")
      .Width(120)
      .AddSorter("Text")
      .AddRenderer("Text")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>("isApproved", "Approved")
      .Width(100)
      .AddSorter("Boolean")
      .AddRenderer("Boolean", "YesNo")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>("isActive", "Active")
      .Width(100)
      .AddSorter("Boolean")
      .AddRenderer("Boolean", "YesNo")
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>("proficiencyCount", "Language Count")
      .Width(100)
      .AddSorter("Number")
      .Build(),
    TableColumnBuilder.Create<AdminSimpleUserResponse>()
      .Key("Edit")
      .Width(50)
      // .AddRenderer('Custom', (_, record) => (<Button type='link' icon={<EditOutlined />} onClick={() => handleEditClick(record)} />))
      .AddRenderer("Custom", (value, record) => {
        return <Link to={RouteConfig.ADMIN_USERS_EDIT(record.id)}>Edit</Link>;
      })
      .Build(),
  ];

  return (
    <div className='admin-users-page'>
      <h1>Users List Page</h1>
      <Space>
        <Link to={RouteConfig.ADMIN_USERS_ADD()}>
          <Button type='primary'>Add New User</Button>
        </Link>
        <div>
          <div>Mass Upload</div>
          {renderUploadFile()}
        </div>
      </Space>

      <Skeleton active loading={loading}>
        <Table
          style={{ marginTop: 12, maxWidth: 1200 }}
          rowKey={NameOf<AdminSimpleUserResponse>("id")}
          className='condensed-table striped-table borderless-table'
          rowClassName={(record, index) => (index % 2 ? "striped-row" : "")}
          pagination={{ defaultPageSize: 50, hideOnSinglePage: true }}
          loading={loading}
          columns={tableColumns}
          dataSource={tableData}
        />
      </Skeleton>
    </div>
  );
};

export default AdminUsersListPage;
