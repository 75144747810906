import React from "react";
import { ReactKey } from "./CoreTypes";

export interface Role {
  key: ReactKey;
  label: string;
  /** This is designed for use in the options, but should really look at the key */
  extra?: string;
  order?: number;
  description?: string;
}

class CoolRoles {
  admin: Role = { key: 1,       label: "Administrator", extra: "admin",       order: 1,   description: "Administrator of the system. No restrictions" };
  moderator: Role = { key: 2,   label: "Moderator",     extra: "moderator",   order: 2,   description: "Moderators are moderators of the system and have access to moderation tools" };
  interpreter: Role = { key: 3, label: "Interpreter",   extra: "interpreter", order: 3,   description: "Interpreters are interpreters and have the ability to create and set appointments, schedules and ability to receive payments" };
  customer: Role = { key: 4,    label: "Customer",      extra: "customer",    order: 4,   description: "Customers are any registered user with the ability to view all interpreters, schedule, request appointments and make payments" };
  none: Role = { key: 99,       label: "None",          extra: "none",        order: 99,  description: "Logged out users or other not found conditions" };
  all: Role[] = [];

  constructor() {
    this.all = Object.entries(this).filter(([key]) => key !== "all").map(([_, value]) => value);
  }
}

const Roles = new CoolRoles();

export default Roles;
