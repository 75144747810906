class ArrayUtil {
  /** Purely for convenience */
  public IsEmpty(source?: unknown[]): boolean {
    return source == null || source.length === 0;
  }

  /**
   * Checks if **all** the values from `values` are in `source`. Written in the form "This includes all of that"
   * @param source Master list of values
   * @param values Values to check if in `source`
   */
  public IncludesAll(source: unknown[], values: unknown[]): boolean {
    return values.every(x => source.includes(x));
  }

  /**
   * Checks if **any** of the values from `values` are in `source`. Written in the form "This includes any of that"
   * @param source Master list of values
   * @param values Values to check if in `source`
   */
  public IncludesSome(source: unknown[], values: unknown[]): boolean {
    return values.some(x => source.includes(x));
  }

  /**
   * Searches an array for a case-insensitive value, returning the array value or the *defaultValue* if not found
   *
   * @example
   * PickFromList('potato', ['Carrot', 'PoTaTo']); // Returns: 'PoTaTo'
   * @param value The value to search in the array
   * @param approvedValues The array of values to search
   * @param defaultValue Default: null - The value to return if *value* is not found
   */
  public PickFromList(value: unknown, approvedValues: string[] | ReadonlyArray<string>, defaultValue: any = null): any | null {
    const valueString = String(value);
    const foundValue = approvedValues.find(x => !x.localeCompare(valueString, undefined, { sensitivity: "base" }));
    return foundValue != null ? foundValue : defaultValue;
  }

  /**
   * Helper function to map a given array without the usually required null checks.
   *
   * Returns a converted array or empty array, so an array of some kind if guaranteed
   */
  public EasyMap<T, U>(value: any[] | undefined | null, mapFunc: (value: T, index: number, array: T[]) => U): U[] {
    // This handles null and undefined just fine
    if (Array.isArray(value)) {
      return value.map(mapFunc);
    }

    return [];
  }
}

export default new ArrayUtil();
