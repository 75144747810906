import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./core/App";
import { AuthenticationProvider } from "./providers/AuthenticationContext";
import { VerifyDevSeverConnection, RenderBuildInfo, DebugUtil } from "./utils";
import { InitializeAxios } from "./config/ApiConfig";
import DebugOverlay from "./core/DebugOverlay";

import "./index.less";

// This will remove the useless validator messages in the console
(window.globalThis as any).ASYNC_VALIDATOR_NO_WARNING = 1;

// Run setup processes that only occur once, such as setting up the API defaults or loading translations
VerifyDevSeverConnection();

// Build info, such as when the build was created
RenderBuildInfo();
const showDebugOverlay = DebugUtil.isDebugEnabled();

// Initialize the API
InitializeAxios();

ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
    <BrowserRouter>
      <AuthenticationProvider>
          {showDebugOverlay && <DebugOverlay />}
          <App />
      </AuthenticationProvider>
    </BrowserRouter>
  // </React.StrictMode>
);
