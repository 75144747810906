/** Awaitable. Takes a `useState` set function and returns a promise to the current value */
function GetCurrentStateAsync<T>(setFunction: (value: T | ((prevState: T) => T)) => void): Promise<T> {
  return new Promise<T>((resolve) => {
    setFunction(prev => {
      resolve(prev);
      return prev;
    });
  });
}

export default GetCurrentStateAsync;
