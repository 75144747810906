import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * This class was made with the intension to streamline the typing and value conversion that normally takes place
 *
 * The issue arises when sending data via post/put/path as the type for 'data' is not 2nd, but 3rd, while the parameter is second, causing confusion if the dev is not aware.
 * To include the 'data' typeguard, it would look like the first example below, requiring an additional import from axios to define the 2nd type, before actually defining the RequestType.
 *
 * **There is no logic reason to change the second type parameter from it's default**
 *
 * The only way to skip it is with a proxied function call. Since we went through all the trouble anyway, a convertFunc param is also provided to handle the typical conversion process that accompanies the interface.create(). See example 3
 * @example
 *  return axios.get<ResultDTO>(url, config);
 * vs
 *  return axios.post<ResultDTO, AxiosResponse<ResponseType>, RequestDTO>(url, data, config);
 *
 * @example
 * // Using Proxy class
 *  return AxiosUtil.get<ResultDTO>(url, config);
 * vs
 *  return AxiosUtil.post<ResultDTO, RequestDTO>(url, data, config);
 *
 * @example
 * // Using convertFunc class
 *  return AxiosUtil.post<ResultDTO, RequestDTO>(url, data, {}, ResultDTO.create);
 * // For arrays
 *  return AxiosUtil.post<ResultDTO[], RequestDTO>(url, data, {}, data => data.map(UserProfileDTO.create));
 */
class AxiosUtil {
  async get<ResponseType = any>(url: string, convertFunc?: (input: ResponseType) => ResponseType, config?: AxiosRequestConfig<void>): Promise<AxiosResponse<ResponseType>> {
    const result = await axios.get<ResponseType, AxiosResponse<ResponseType>, void>(url, config);
    if (convertFunc != null) {
      result.data = convertFunc(result.data);
    }
    return result;
  }

  async delete<ResponseType = any>(url: string, convertFunc?: (input: ResponseType) => ResponseType, config?: AxiosRequestConfig<void>): Promise<AxiosResponse<ResponseType>> {
    const result = await axios.delete<ResponseType, AxiosResponse<ResponseType>, void>(url, config);
    if (convertFunc != null) {
      result.data = convertFunc(result.data);
    }
    return result;
  }

  async head<ResponseType = any>(url: string, convertFunc?: (input: ResponseType) => ResponseType, config?: AxiosRequestConfig<void>): Promise<AxiosResponse<ResponseType>> {
    const result = await axios.head<ResponseType, AxiosResponse<ResponseType>, void>(url, config);
    if (convertFunc != null) {
      result.data = convertFunc(result.data);
    }
    return result;
  }

  async options<ResponseType = any>(url: string, convertFunc?: (input: ResponseType) => ResponseType, config?: AxiosRequestConfig<void>): Promise<AxiosResponse<ResponseType>> {
    const result = await axios.options<ResponseType, AxiosResponse<ResponseType>, void>(url, config);
    if (convertFunc != null) {
      result.data = convertFunc(result.data);
    }
    return result;
  }

  async post<ResponseType = any, RequestType = any>(url: string, data?: RequestType, convertFunc?: (input: ResponseType) => ResponseType, config?: AxiosRequestConfig<RequestType>): Promise<AxiosResponse<ResponseType>> {
    const result = await axios.post<ResponseType, AxiosResponse<ResponseType>, RequestType>(url, data, config);
    if (convertFunc != null) {
      result.data = convertFunc(result.data);
    }
    return result;
  }

  async put<ResponseType = any, RequestType = any>(url: string, data?: RequestType, convertFunc?: (input: ResponseType) => ResponseType, config?: AxiosRequestConfig<RequestType>): Promise<AxiosResponse<ResponseType>> {
    const result = await axios.put<ResponseType, AxiosResponse<ResponseType>, RequestType>(url, data, config);
    if (convertFunc != null) {
      result.data = convertFunc(result.data);
    }
    return result;
  }

  async patch<ResponseType = any, RequestType = any>(url: string, data?: RequestType, convertFunc?: (input: ResponseType) => ResponseType, config?: AxiosRequestConfig<RequestType>): Promise<AxiosResponse<ResponseType>> {
    const result = await axios.patch<ResponseType, AxiosResponse<ResponseType>, RequestType>(url, data, config);
    if (convertFunc != null) {
      result.data = convertFunc(result.data);
    }
    return result;
  }

  async postForm<ResponseType = any, RequestType = any>(url: string, data: RequestType, convertFunc?: (input: ResponseType) => ResponseType, config?: AxiosRequestConfig<RequestType>): Promise<AxiosResponse<ResponseType>> {
    let myConfig: AxiosRequestConfig<RequestType> = config != null ? {...config} : { headers: {}};
    myConfig.headers = {
      ...(myConfig.headers || {}),
      "Content-Type": "multipart/form-data"
    };
    const result = await axios.post<ResponseType, AxiosResponse<ResponseType>, RequestType>(url, data, myConfig);
    if (convertFunc != null) {
      result.data = convertFunc(result.data);
    }
    return result;
  }
}

export default new AxiosUtil();