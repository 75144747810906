import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input } from "antd";
import axios from "axios";
import RouteConfig from "src/config/RouteConfig";
import { NameOf, NotificationUtil, ObjectUtil } from "src/utils";
import AuthorizationController from "src/api/AuthorizationController";
import PasswordResetResponseDTO from "src/models/generated/PasswordResetResponseDTO";
import { useQueryParam } from "src/hooks";

export interface PasswordResetFormData extends PasswordResetResponseDTO {
  /** You should not use this property, it's for the internal form object */
  confirmPassword: string;
}

const LoginResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm<PasswordResetFormData>();
  // These query params come in from the email link
  const [emailParam, setEmailParam] = useQueryParam("email");
  const [tokenParam, setTokenParam] = useQueryParam("id"); // TODO: Please verify this is the correct name ~nya
  const [loading, setLoading] = useState(false);

  const handleOnFinish = async (values: PasswordResetFormData) => {
    // Trim values. We are including password in this because you should not have a space in your password. That is just bad
    values = ObjectUtil.TrimValues(values);

    // Okay, we get to submit to the API now!
    try {
      setLoading(true);
      const results = await AuthorizationController.passwordResetResponse(PasswordResetResponseDTO.create({...values, email: emailParam!, code: tokenParam!}));

      // Assuming we got here, the reset was a success! Send the user to the login page
      NotificationUtil.success({
        key: "password-reset-success",
        message: "Password Reset",
        description: "Your password has been reset successfully. Please login with your new password.",
        duration: 0
      });

      navigate(RouteConfig.LOGIN());
    } catch (error) {
      if (axios.isAxiosError(error)) {
        NotificationUtil.error({
          key: "password-reset-error",
          message: "Error",
          description: (error.response?.data as any)?.message ?? error.message
        });
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    // If we don't have an email or token, send the user back to the login page
    if (!emailParam || !tokenParam) {
      navigate(RouteConfig.LOGIN());
    }
  }, []);

  return (
    <div className='login-reset-password' style={{ width: "min(350px, calc(100vw - 200px))" }}>
      <h1>Password Reset</h1>

      <div className='login-form'>
        <Form
          size='large'
          layout='vertical'
          requiredMark={false}
          form={form}
          onFinish={handleOnFinish}
        >
          <Form.Item
            name={NameOf<PasswordResetFormData>("password")}
            rules={[
              { required: true, message: "Password is required" }
            ]}
          >
            <Input.Password placeholder='New Password' disabled={loading} />
          </Form.Item>

          <Form.Item
            name={NameOf<PasswordResetFormData>("confirmPassword")}
            rules={[
              { required: true, message: "Please confirm your password" },
              {
                validator: async (_, value) => {
                  if (value !== form.getFieldValue(NameOf<PasswordResetFormData>("password"))) {
                    return Promise.reject(new Error("Passwords do not match"));
                  }
                }
              }
            ]}
          >
            <Input.Password placeholder='Confirm Password' disabled={loading} />
          </Form.Item>

          <Form.Item style={{ marginTop: 8 }}>
            <Button type='primary' htmlType='submit' block loading={loading}>Reset Password</Button>
          </Form.Item>
        </Form>
      </div>

    </div>
  );
};

export default LoginResetPasswordPage;
